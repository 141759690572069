import { Skeleton } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useVaultDetailsFromComptrollerQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import { type Address, type Hex, formatEther } from "viem";

interface ExchangeEthAndBuySharesInputs {
  comptrollerProxy: Address;
  minSharesQuantity: bigint;
  exchange: Address;
  exchangeApproveTarget: Address;
  exchangeData: Hex;
  minInvestmentAmount: bigint;
}

export const exchangeEthAndBuyShares: FunctionConfig<ExchangeEthAndBuySharesInputs> = {
  signature:
    "function exchangeEthAndBuyShares(address comptrollerProxy, uint256 minSharesQuantity, address exchange, address exchangeApproveTarget, bytes exchangeData, uint256 minInvestmentAmount)",

  Description({ inputs, value }) {
    const { environment } = useGlobals();
    const query = useVaultDetailsFromComptrollerQuery({
      variables: { id: inputs.comptrollerProxy.toLowerCase() },
    });

    const vault = query.data?.comptroller?.vault;
    const amount = value && formatEther(value);

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    return (
      <>
        Deposit {amount} {environment.network.currency.nativeToken.symbol} to buy shares for{" "}
        {vault?.name ?? "this vault"}.
      </>
    );
  },

  Label() {
    return <>Buy Shares</>;
  },
};
