import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { useNetwork } from "components/providers/NetworkProvider";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const convexVotingClaimRewards: ExternalPositionHandler<Integrations.Convex.ClaimVotingRewardsArgs> = {
  Description({ args: { allTokensToTransfer } }) {
    const { environment } = useNetwork();

    const assets = allTokensToTransfer.map((token) => environment.getAsset(token));

    return (
      <>
        Claiming tokens: <br />
        {assets.map((asset) => (
          <span key={asset.id}>
            {asset.symbol}
            <br />
          </span>
        ))}
      </>
    );
  },
  Label() {
    return <>Claim rewards on Convex Locking/Delegating</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Convex.claimVotingRewardsDecode(encodedCallArgs),
};

export const convexVotingLock: ExternalPositionHandler<Integrations.Convex.LockArgs> = {
  Description({ args: { amount } }) {
    const { environment } = useNetwork();

    const { cvx } = environment.namedTokens;

    return (
      <>
        Locking <BigIntDisplay value={amount} decimals={cvx.decimals} numberFormat={{ currency: cvx.symbol }} />
      </>
    );
  },
  Label() {
    return <>Lock on Convex Locking/Delegating</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Convex.lockDecode(encodedCallArgs),
};

export const convexVotingRelock: ExternalPositionHandler = {
  Description() {
    return <>Relock all unlocked CVX tokens inside lockers</>;
  },
  Label() {
    return <>Relock on Convex Locking/Delegating</>;
  },
  decodeExternalPositionArgs: () => null,
};

export const convexVotingWithdraw: ExternalPositionHandler = {
  Description() {
    return <>Withdraw all unlocked CVX tokens inside lockers</>;
  },
  Label() {
    return <>Withdraw on Convex Locking/Delegating</>;
  },
  decodeExternalPositionArgs: () => null,
};

export const convexVotingDelegate: ExternalPositionHandler<Integrations.Convex.DelegateArgs> = {
  Description({ args: { delegate } }) {
    return <>Delegate votes to address: {delegate}</>;
  },
  Label() {
    return <>Delegate votes on Convex Locking/Delegating</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Convex.delegateDecode(encodedCallArgs),
};

export const createConvexVotingExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Create Convex Locking/Delegating external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = convexVotingLock.decodeExternalPositionArgs(actionArgs);

    return <convexVotingLock.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Create Convex Locking/Delegating external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = convexVotingLock.decodeExternalPositionArgs(actionArgs);

    return <convexVotingLock.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Create Convex Locking/Delegating external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = convexVotingLock.decodeExternalPositionArgs(actionArgs);

    const Summary = getDefaultExtensionSummary(convexVotingLock.Label, convexVotingLock.Description);

    return <Summary args={args} />;
  },
};
