import { Address, BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { useTheGraphDelegationSharesToTokensQuery } from "queries/backend";
import { client } from "utils/backend";
import { isAddressEqual, zeroAddress } from "viem";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const theGraphDelegationDelegate: ExternalPositionHandler<Integrations.TheGraph.DelegateArgs> = {
  Description({ args: { indexer, tokens } }) {
    const { environment } = useGlobals();

    const { grt } = environment.namedTokens;

    return (
      <>
        Delegate <BigIntDisplay numberFormat={{ currency: grt.symbol }} value={tokens} decimals={grt.decimals} /> to{" "}
        <Address address={indexer} />
      </>
    );
  },
  Label() {
    return <>Delegate GRT</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.TheGraph.delegateDecode(encodedCallArgs),
};

export const theGraphDelegationUndelegate: ExternalPositionHandler<Integrations.TheGraph.UndelegateArgs> = {
  Description({ args: { indexer, shares } }) {
    const { environment } = useGlobals();
    const { deployment } = useNetwork();

    const { grt } = environment.namedTokens;

    const theGraphDelegationSharesToTokensQuery = useTheGraphDelegationSharesToTokensQuery({
      client,
      variables: {
        shares: shares.toString(),
        network: deployment,
        indexer,
      },
    });

    if (theGraphDelegationSharesToTokensQuery.loading) {
      return null;
    }

    return (
      <>
        Undelegate{" "}
        <BigIntDisplay
          numberFormat={{ currency: grt.symbol }}
          value={
            theGraphDelegationSharesToTokensQuery.data?.theGraphDelegationSharesToTokens?.tokens
              ? BigInt(theGraphDelegationSharesToTokensQuery.data.theGraphDelegationSharesToTokens.tokens)
              : undefined
          }
          decimals={grt.decimals}
        />{" "}
        from <Address address={indexer} />
      </>
    );
  },
  Label() {
    return <>Undelegate GRT</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.TheGraph.undelegateDecode(encodedCallArgs),
};

export const theGraphDelegationWithdraw: ExternalPositionHandler<Integrations.TheGraph.WithdrawArgs> = {
  Description({ args: { indexer, nextIndexer } }) {
    if (isAddressEqual(nextIndexer, zeroAddress)) {
      return <>Withdraw from {indexer}</>;
    }

    return (
      <>
        Redelegate from <Address address={indexer} /> to <Address address={nextIndexer} />
      </>
    );
  },
  Label({ args: { nextIndexer } }) {
    return isAddressEqual(nextIndexer, zeroAddress) ? <>Withdraw GRT</> : <>Redelegate GRT</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.TheGraph.withdrawDecode(encodedCallArgs),
};

export const createTheGraphDelegationExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Create The Graph Delegation external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = theGraphDelegationDelegate.decodeExternalPositionArgs(actionArgs);

    return <theGraphDelegationDelegate.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Create The Graph Delegation external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = theGraphDelegationDelegate.decodeExternalPositionArgs(actionArgs);

    return <theGraphDelegationDelegate.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Create The Graph Delegation external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = theGraphDelegationDelegate.decodeExternalPositionArgs(actionArgs);

    const Summary = getDefaultExtensionSummary(
      theGraphDelegationDelegate.Label,
      theGraphDelegationDelegate.Description,
    );

    return <Summary args={args} />;
  },
};
