import { Network } from "@enzymefinance/environment";
import { address, network } from "@enzymefinance/validation";
import { getAddress } from "viem";
import { z } from "zod";
import { createUseValidatedQuery } from "./useValidatedQuery";

interface GnosisSafeResponse {
  address?: string;
  message?: string;
}

const apiBases = {
  [Network.ARBITRUM]: "https://safe-transaction-arbitrum.safe.global/api/v1",
  [Network.ETHEREUM]: "https://safe-transaction-mainnet.safe.global/api/v1",
  [Network.POLYGON]: "https://safe-transaction-polygon.safe.global/api/v1",
};

export const useIsGnosisSafeWallet = createUseValidatedQuery(["is-smart-contract-wallet"], {
  paramsSchema: z.object({
    network: network(),
    address: address({ allowZeroAddress: false }),
  }),
  responseSchema: z.boolean(),
  queryFn: async (params) => {
    const checksumAddress = getAddress(params.address);
    const endpoint = `${apiBases[params.network]}/safes/${checksumAddress}/`;

    const result = await fetch(endpoint);

    if (result.status === 404) {
      return false;
    }

    const parsedResult = (await result.json()) as GnosisSafeResponse;

    if (parsedResult.address === checksumAddress) {
      return true;
    }

    return false;
  },
});
