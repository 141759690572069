import type { Address } from "@enzymefinance/environment";
import { AdapterType, adapterDefinitions } from "@enzymefinance/environment";
import { type Environment, isAdapterDefinition } from "@enzymefinance/environment";
import { type Adapter, getAdapterIconUrl, isTruthy, toAdapter } from "@enzymefinance/utils";
import type { GlobalsContext } from "components/providers/GlobalsProvider";
import { useGlobals } from "components/providers/GlobalsProvider";

interface LimitedAdapterDefinition {
  label: string;
  icon: string;
}

// Map old adapter addresses with the current adapters, just for icon/label display purposes on the Network Activity page
const legacyAdapterAddressMap: Record<string, (typeof adapterDefinitions)[AdapterType]> = {
  "0xde4a42052db0fb0e8935c9f3015b8cd56a3ddf43": adapterDefinitions[AdapterType.TRACKED_ASSETS],
  "0xea05f4a383adce7f1e9cc1b6029b51d7260e53aa": adapterDefinitions[AdapterType.TRACKED_ASSETS],
  "0x07698c3fa26a62ec6320872bf2950ba160a2dd7c": adapterDefinitions[AdapterType.SYNTHETIX],
  "0x0bad0886c5976072549687d5ffcd26803c5ba167": adapterDefinitions[AdapterType.AAVE_V2],
  "0x14c6b99affc61e9b0753146f3437a223d0c58279": adapterDefinitions[AdapterType.AAVE_V2],
  "0xd2bdd90629351cc1cd4427917ae23f97fd9d9933": adapterDefinitions[AdapterType.AAVE_V2],
  "0x2fada67abe30a700026a4f5f6a6cf80c978a90cf": adapterDefinitions[AdapterType.AAVE_V2],
  "0x1990d8bc382d38fa21ee3b570e9d1e6272152165": adapterDefinitions[AdapterType.CURVE_EXCHANGE],
  "0x1c1b94812faf3de496a2634b36c1fbc6e5c222e7": adapterDefinitions[AdapterType.PARASWAP_V4],
  "0x2f7918d9a98c199173fb8ca989b408b4fb1ea441": adapterDefinitions[AdapterType.IDLE],
  "0x32d0cb26c2964749bd5d10ba93695e52b4b57ea3": adapterDefinitions[AdapterType.AAVE_V2],
  "0x3fb61ad8aee5d507c2997e13d6fe7f5d907f36bf": adapterDefinitions[AdapterType.PARASWAP_V5],
  "0x402a81ad2972a017b4564453e69afae2b006a7e2": adapterDefinitions[AdapterType.CURVE_EXCHANGE],
  "0x4205073e7ad2f9896f827dbce496dd2306db602e": adapterDefinitions[AdapterType.ZERO_EX_V2],
  "0x49aa3b8ccbf8525d8f065a83f1bc780f6779ab75": adapterDefinitions[AdapterType.PARASWAP_V4],
  "0x4db2cc3a6040e8a826393f662b428ae830a68f7d": adapterDefinitions[AdapterType.CONVEX],
  "0xe8943f116c974c05f637920fff3dfe0463528d9a": adapterDefinitions[AdapterType.CONVEX],
  "0x581a1e865285144c32ebd8205ca144156920b5fd": adapterDefinitions[AdapterType.AURA],
  "0x4ff16eff3b6d2175a513ef4c5b95f5f4d2f05179": adapterDefinitions[AdapterType.KYBER_NETWORK],
  "0x56a1892b2276bbb9968d1b5aa0000a71bf0fa7b8": adapterDefinitions[AdapterType.PARASWAP_V4],
  "0x63765ae5df9bb8af9d8bc976e595b79907cf341c": adapterDefinitions[AdapterType.ALPHA_HOMORA_V1],
  "0x649b6cc835c8fe3d3b94b6a9c155f791bf500dfe": adapterDefinitions[AdapterType.IDLE],
  "0x8481150a0f36c98ee859e6c7b46d61fdd836768f": adapterDefinitions[AdapterType.UNISWAP_V2],
  "0x893b4fa60efbabfc35a54795e2ea9eb97344f64d": adapterDefinitions[AdapterType.CURVE_LIQUIDITY_EURS],
  "0x8ea6ca02274e1b05b70c11058213adc667258c3d": adapterDefinitions[AdapterType.PARASWAP_V4],
  "0x8f96e9bfa455af4f6f4ee81d0dad40ed90e1fd55": adapterDefinitions[AdapterType.PARASWAP_V5],
  "0x963e9c15a26d74085abfc8b48280b650426f998e": adapterDefinitions[AdapterType.KYBER_NETWORK],
  "0x972318a0f4935c3153a2aa4c81274dff621b360b": adapterDefinitions[AdapterType.UNISWAP_V2],
  "0x592a4935f7b8e95b513eafb9f87a380e0d9314e4": adapterDefinitions[AdapterType.UNISWAP_V2],
  "0x9b6cd2195d3c275875cb469a59dd437995712550": adapterDefinitions[AdapterType.ZERO_EX_V2],
  "0xa6402b88634029b84f79370edf187250aa6530e7": adapterDefinitions[AdapterType.PARASWAP_V5],
  "0xb7bfd3582582a82ef2a2f0df0adf705f35a07d4b": adapterDefinitions[AdapterType.COMPOUND_V2],
  "0xb98c40bdd6d4c58d3cd143c3c435ec6399514293": adapterDefinitions[AdapterType.PARASWAP_V5],
  "0xbf3411973f09c32e8c4e67d1345dddc5f9c7535a": adapterDefinitions[AdapterType.SYNTHETIX],
  "0xc80a7a904873c57478bb86d8b23e7283e3af5ac2": adapterDefinitions[AdapterType.PARASWAP_V5],
  "0x06ebb3ed77dda3d7c11a3b1d7e1df9b2bf8258c2": adapterDefinitions[AdapterType.OLYMPUS_V2],
  "0xcccee3084d491efb3fbc107bb28ed0316cebff11": adapterDefinitions[AdapterType.POOLTOGETHER_V4],
  "0xd050dc9e75f24ae653d282d0cfb772871729e710": adapterDefinitions[AdapterType.COMPOUND_V2],
  "0xda27d95bd940cac017f9484175e5512d3e4b0662": adapterDefinitions[AdapterType.YEARN_VAULT_V2],
  "0xdfd3c9210b1dba7c94fecb65e23c944012ad365b": adapterDefinitions[AdapterType.CURVE_LIQUIDITY],
  "0xed6bdfa2725da6687bc89bcb0bdb1e5deae15838": adapterDefinitions[AdapterType.ALPHA_HOMORA_V1],
  "0x78ba80c8517a4f3146fd76a3642fa57b77f331eb": adapterDefinitions[AdapterType.CURVE_LIQUIDITY_AAVE],
  "0xf6f7cc9464cb15e0a5b116d738dca88434bba00a": adapterDefinitions[AdapterType.CURVE_LIQUIDITY_AAVE],
  "0x83e249d603459d2a926ee85d8ba8d0aeabe101f2": adapterDefinitions[AdapterType.CURVE_LIQUIDITY_AAVE],
  "0xbd81a0ef03f4ed89f73a30eb33137787bf92e177": adapterDefinitions[AdapterType.CURVE_LIQUIDITY_SETH],
  "0xee91e3bb4752f358ddaac6ab825536698b239546": adapterDefinitions[AdapterType.CURVE_LIQUIDITY_SETH],
  "0x87db8e815f9bde3b5b686e3e892faf0e86481c44": adapterDefinitions[AdapterType.CURVE_LIQUIDITY_SETH],
  "0x6367bb496390ddf0ef508db2992f53dbf376b1f4": adapterDefinitions[AdapterType.CURVE_LIQUIDITY_STETH],
  "0x1453e5a5c029cdd91f584c603818baf2ab4ce663": adapterDefinitions[AdapterType.CURVE_LIQUIDITY_STETH],
  "0x3bb38e67033e24e604e53964a99d914f8b216521": adapterDefinitions[AdapterType.CURVE_LIQUIDITY_STETH],
  "0x2a5106122289d1891abb9b5e69c686680595d616": adapterDefinitions[AdapterType.PARASWAP_V5],
  "0xe07a03acbde1fa73fa75ec6b294f17d892514328": adapterDefinitions[AdapterType.PARASWAP_V5],
  "0xe9293a7fa3acd39955d3d33f8457c0af655879d4": adapterDefinitions[AdapterType.PARASWAP_V5],
  "0xe65df28eeec94bf2d21192fccb67852e93179daa": adapterDefinitions[AdapterType.BALANCER_V2],
  "0x98302d9486599332890710eddd29b3e317d36104": adapterDefinitions[AdapterType.BALANCER_V2],
  "0x5966cbe0167d95ea03ffad0bd9091849a52dfbd5": adapterDefinitions[AdapterType.ZERO_EX_V4],
  "0x34558aa452cac926d6a78cc148ee1220e94fb687": adapterDefinitions[AdapterType.ZERO_EX_V4],
  "0x18e469eeb685a6cbfd69b0537ebbc458c8fc7723": adapterDefinitions[AdapterType.ZERO_EX_V4_PMM_KYC],
  "0x474c8d4a0e53b7235c6f8fc27c9b6406a32dd0b1": adapterDefinitions[AdapterType.IDLE],
  "0x5d2135fa26a6202f2d39fdba61bcf53bb04b8434": adapterDefinitions[AdapterType.IDLE],
} as const;

export function useAdapterForDisplayPurposes(adapterAddress: Address | string | undefined) {
  const { getAdapterByAddress } = useGlobals();

  return getAdapterForDisplayPurposes(adapterAddress, getAdapterByAddress);
}

const unknown = {
  icon: getAdapterIconUrl(AdapterType.UNKNOWN),
  label: adapterDefinitions[AdapterType.UNKNOWN].name,
};

export function getAdapterForDisplayPurposes(
  adapterAddress: Address | string | undefined,
  getAdapterByAddress: GlobalsContext["getAdapterByAddress"],
): LimitedAdapterDefinition {
  if (!adapterAddress) {
    return unknown;
  }

  const adapter = getAdapterByAddress(adapterAddress);

  if (adapter) {
    return {
      icon: getAdapterIconUrl(adapter.type),
      label: adapter.name,
    };
  }

  const legacyAdapter = legacyAdapterAddressMap[adapterAddress.toLowerCase()];

  if (typeof legacyAdapter !== "undefined") {
    return {
      icon: getAdapterIconUrl(legacyAdapter.type),
      label: legacyAdapter.name,
    };
  }

  return unknown;
}

export function addressesToAdaptersIncludingLegacy({
  addresses,
  environment,
}: { addresses: Address[]; environment: Environment }): Adapter[] {
  return addresses
    .map((address) => {
      const lowerCaseAddress = address.toLowerCase();

      const adapter = environment.adapters[lowerCaseAddress];

      if (adapter) {
        return adapter;
      }

      const legacyAdapter = legacyAdapterAddressMap[lowerCaseAddress];

      const legacyAdapterDefinition = legacyAdapter ? { ...legacyAdapter, address: lowerCaseAddress } : undefined;

      if (isAdapterDefinition(legacyAdapterDefinition)) {
        return legacyAdapterDefinition;
      }

      return undefined;
    })
    .filter(isTruthy)
    .map(toAdapter);
}
