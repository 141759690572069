import type { NetworkDefinition } from "@enzymefinance/environment";
import { Network } from "@enzymefinance/environment";
import type { BaseIconProps } from "@enzymefinance/ethereum-ui";
import { BaseIcon } from "@enzymefinance/ethereum-ui";
import { defaultIconSize } from "@enzymefinance/ui";

import arbitrum from "../../assets/networks/arbitrum.svg";
import ethereum from "../../assets/networks/ethereum.svg";
import polygon from "../../assets/networks/polygon.svg";

const urls: Record<Network, string> = {
  [Network.ARBITRUM]: arbitrum,
  [Network.ETHEREUM]: ethereum,
  [Network.POLYGON]: polygon,
};

export interface NetworkIconProps extends Omit<BaseIconProps, "id" | "network" | "url"> {
  network: NetworkDefinition;
}

export function NetworkIcon({ network, size = defaultIconSize, ...props }: NetworkIconProps) {
  const { label, id } = network;
  const url = urls[id];

  if (!url) {
    return null;
  }

  return <BaseIcon alt={label} title={label} size={size} url={url} {...props} />;
}
