import { Network } from "@enzymefinance/environment";

const hostname = window.location.hostname;

export const isProduction =
  hostname === "app.enzyme.finance" || hostname.endsWith(".enzyme.community") || hostname.endsWith(".defistrategy.io");
export const isStaging = hostname === "app.enzyme.kitchen";
export const isPreview = hostname.endsWith(".pages.dev");
export const isLocal = hostname === "localhost" || hostname === "127.0.0.1" || hostname === "app.local.enzyme.finance";

export const supportEmailAddress = "support@enzyme.finance";

export const defaultTransactionSlippage = 0.01;

export const bigDecimalMaxUint256 = "115792089237316195423570985008687900000000000000000000000000000000000000000000";

export const maxUint256BigInt = 2n ** 256n - 1n;

export const gasRelayerMaxAmount = { [Network.ARBITRUM]: "0.1", [Network.ETHEREUM]: "1", [Network.POLYGON]: "25" };
