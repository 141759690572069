import { type Address, type Asset, Deployment, toAddress } from "@enzymefinance/environment";
import { TokenLabel, TokenPicker } from "@enzymefinance/ethereum-ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import type { VaultDetailsFragment } from "queries/core";

const allowedAdditionalDepositAssetsForSpecificVaults: {
  [key in Deployment]: {
    readonly [key: Address]: readonly Address[];
  };
} = {
  [Deployment.ARBITRUM]: {},
  [Deployment.ETHEREUM]: {
    // LBTC Smart vault
    "0xdcee864624ac3f93fd21b865408f4843f6d51416": [
      "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599", // WBTC
      "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2", // WETH
    ],
    // ETHx Hyperloop
    "0x040a9562201b2a3456a7c9052d88ce37e994ee9d": [
      "0xae7ab96520de3a18e5e111b5eaab095312d7fe84", // stETH
      "0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0", // wstETH
      "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2", // WETH
    ],
  },
  [Deployment.POLYGON]: {
    "0xe7178a995867d86b0504730dc4cefb409fb0a004": [
      "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
      "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    ],
  },
  [Deployment.TESTNET]: {
    "0x40447544eaa91b4817ecf2d77b0daf5c8bb279db": [
      "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
      "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    ],
  },
} as const;

export function DepositTokenPicker({
  vault,
  depositAsset,
  setDepositAsset,
}: {
  vault: VaultDetailsFragment;
  depositAsset: Asset;
  setDepositAsset: (asset: Asset) => void;
}) {
  const { environment } = useGlobals();
  const id = toAddress(vault.id);
  const denominationAsset = environment.getAsset(toAddress(vault.comptroller.denomination.id));
  const nativeToken = environment.network.currency.nativeToken;

  const otherAssetsToDeposit =
    allowedAdditionalDepositAssetsForSpecificVaults[environment.deployment.slug][id]?.map((asset) =>
      environment.getAsset(asset),
    ) ?? [];

  return (
    <div>
      <TokenPicker
        id="deposit-token-picker"
        label=""
        isClearable={false}
        onChange={(value) => {
          if (value === null) {
            return;
          }
          setDepositAsset(value);
        }}
        options={[denominationAsset, nativeToken, ...otherAssetsToDeposit]}
      >
        <TokenPicker.Button className="w-48 rounded-l-none border-0 bg-base-400">
          <TokenLabel size={5} asset={depositAsset} hideName={true} />
        </TokenPicker.Button>
      </TokenPicker>
    </div>
  );
}
